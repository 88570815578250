<template>
  <div class="blog-detail width-100">
    <heard-vue></heard-vue>
    <!-- 主体区域开始 -->
    <div class="posi-rel width-100">
      <div class="detail-center space-between" v-if="blogDetail">
        <div class="detail-content">
          <img :src="blogDetail.imgs" class="detail-img width-100" alt="" />
          <p class="content-title">{{ blogDetail.title }}</p>
          <div class="row content-time">
            <div class="row content-time-read">
              <img src="../../assets/blog-5.png" alt="" />
              <div>read：{{ blogDetail.read_num }}</div>
            </div>
            <div class="row">
              <img src="../../assets/blog-6.png" alt="" />
              <div>{{ blogDetail.create_time }}</div>
            </div>
          </div>
          <!-- 富文本内容 -->
          <div class="content-ritch width-100">
            <div v-html="blogDetail.content"></div>
          </div>
        </div>
        <div class="detail-third">
          <div class="width-100 third-share column-between">
            <div class="row">
              <img src="../../assets/blog-2.png" class="img-one pointer" @click="navTo(siteObj.youtube)" alt="" />
              <img src="../../assets/blog-3.png" class="pointer" @click="navTo(siteObj.youtube)" alt="" />
            </div>
            <div>Follow us on social media</div>
          </div>
          <div class="width-100 expand-third"  :style="{backgroundImage:`url(${homeBg})`}">
            <div class="width-100 height-100 expand-bg direction">
                 <div>{{blogObj.redirct_title}}</div>
                <div  v-html="blogObj.redirct_content"></div>
              <div class="flex-center bg-color expand-button"  @click="navToOne(blogObj.redirct_url)">Yes, I'm here</div>
            </div>
          </div>
        </div>
      </div>
     <!-- <img
        src="../../assets/about-5.png"
        v-if="!emailShow"
        class="emain-img posi-abs pointer"
        alt=""
        @click="emailShow = true"
      /> -->
      <mail-vue v-if="emailShow" @hide="emailShow = false"></mail-vue>
    </div>
    <!-- 主体区域结束 -->
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index";
export default {
  components: {
    heardVue,
    feetVue,
    mailVue,
  },

  data() {
    return {
      emailShow: false,
      loading: null,
      blogDetail: null,
      siteObj:null,
      blogObj:{},
      homeBg:''
    };
  },

  created() {
    if (this.$route.query.id) {
      this.getBlogDetail(this.$route.query.id);
    } else {
      this.$router.push({ path: "/blog" });
    }
    this.siteObj = JSON.parse(localStorage.getItem("siteObj"));
    this.getOtherSite()
  },

  mounted() {},

  methods: {
    getOtherSite(){
      api.getOtherSite().then(res=>{
        const reg = new RegExp("\n", "g");
         res.data.blog.content = decodeURIComponent(res.data.blog.content).replace(
            reg,
            "<br>"
          );
            res.data.blog.redirct_content = decodeURIComponent(res.data.blog.redirct_content).replace(
            reg,
            "<br>"
          );
        this.blogObj=res.data.blog
        if(res.data.blog.redirct_img){
          this.homeBg=res.data.blog.redirct_img
        }else{
          this.homeBg=require('@/assets/blog-4.png')
        }
      })
    },
    getBlogDetail(id) {
      this.loading = this.$loading({
        lock: true,
        text: "Under load...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api
        .getBlogDetail({ id })
        .then((res) => {
          this.blogDetail = res.data;
          setTimeout(() => {
            this.loading.close();
          }, 500);
        })
        .catch((err) => {
          this.loading.close();
        });
    },
    navTo(url){
      window.open(url,"_blank");
    }
  },
};
</script>
<style lang="less" scoped>
.blog-detail {
  background: #f8f8f8;
  .detail-center {
    width: 1226px;
    margin: 0 auto;
    padding: 64px 0 76px 0;
    align-items: flex-start;
    .detail-content {
      width: 892px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      padding: 0 20px 20px 20px;
      .detail-img {
        height: 492px;
        margin-bottom: 24px;
      }
      .content-title {
        padding-left: 23px;
        font-weight: bold;
        color: #333333;
        font-size: 30px;
        margin-bottom: 24px;
      }
      .content-time {
        padding-left: 12px;
        margin-bottom: 36px;
        color: #666666;
        font-size: 14px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
        .content-time-read {
          margin-right: 48px;
        }
      }
      .content-ritch {
        padding: 0 12px;
        p,
        div,
        span {
          white-space: pre-wrap !important;
        }
      }
    }
    .detail-third {
      width: 320px;
      .third-share {
        height: 114px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 17px 0 7px 20px;
        color: #666666;
        font-size: 18px;
        margin-bottom: 30px;
        img {
          width: 49px;
          height: 49px;
        }
        .img-one {
          margin-right: 25px;
        }
      }
      .expand-third {
        height: 372px;
        background-image: url("../../assets/blog-4.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .expand-bg {
          background: rgba(0, 0, 0, 0.2);
          font-weight: bold;
          color: #ffffff;
          font-size: 24px;
          padding-top: 72px;
          div:nth-child(2) {
            margin-top: 18px;
            margin-bottom: 15px;
          }
          .expand-button {
            width: 255px;
            height: 42px;
            font-size: 16px;
            margin-top: 70px;
          }
        }
      }
    }
  }
  .emain-img {
    left: 60px;
    bottom: 70px;
    width: 64px;
    height: 64px;
  }
}
</style>
